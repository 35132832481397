import { Model } from '@vuex-orm/core'
import UserSpec from "@/model/UserSpec";
import ComPlace from "@/model/ComPlace";

export default class UserSpecPlan extends Model {
    static entity = 'userSpecPlans'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            begin: this.attr(null),
            end: this.attr(null),
            gridMinutes: this.attr(null),

            userSpec_id: this.attr(null),
            userSpec: this.belongsTo(UserSpec, 'userSpec_id'),

            comPlace_id: this.attr(null),
            comPlace: this.belongsTo(ComPlace, 'comPlace_id'),
        }
    }
}