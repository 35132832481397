import { Model } from '@vuex-orm/core'
import ComBuilding from "@/model/ComBuilding";
import ComDiv from "@/model/ComDiv";
import Com from "@/model/Com";

export default class ComBranch extends Model {
    static entity = 'comBranchs'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),
            color: this.attr(null),
            workPlan: this.attr(null),

            com_id: this.attr(null),
            com: this.belongsTo(Com, 'com_id'),

            comDivs: this.hasMany(ComDiv, 'comBranch_id'),
            comBuildings: this.hasMany(ComBuilding, 'comBranch_id'),
        }
    }
}