
export default class WS{
    ws=null
    static STATE_CONNECTING = WebSocket.CONNECTING
    static STATE_OPEN = WebSocket.OPEN
    static STATE_CLOSED = WebSocket.CLOSED

    _stateCallbacks = []
    addStateCallback(v){
        this._stateCallbacks.push(v);
    }

    connect(){
        if(this.ws!==null && (this.ws.readyState===WebSocket.CONNECTING || this.ws.readyState===WebSocket.OPEN))
            return;

        this._stateCallbacks.forEach(v=>{
            v({status: WS.STATE_CONNECTING, data: null})
        })

        let ws = new WebSocket((window.location.protocol==='https:' ? 'wss' : 'ws')+'://'+window.location.host+'/ws/');

        ws.onopen = ()=>{
            console.log('ws','connected');
            if(typeof localStorage.wsHistoryLastId!=='undefined')
                this.ws.send('["history",'+localStorage.wsHistoryLastId+']');

            this._stateCallbacks.forEach(v=>{
                v({status: WS.STATE_OPEN, data: null})
            })
        };

        ws.onmessage = (event)=>{
            let message = JSON.parse(event.data);
            console.log('ws','message', message);
            let channel = message[0];
            let id = message[1];
            //let created = message[2];
            let data = message[3];

            localStorage.wsHistoryLastId=id;

            this._stateCallbacks.forEach(v=>{
                v({
                    status: WS.STATE_OPEN,
                    data: {
                        channel: channel,
                        data: data,
                    }
                })
            })
        };

        ws.onclose = (event)=>{
            if (event.wasClean)
                console.log('ws', 'connection closed');
            else
                console.error('ws', 'connection died');
            console.log(event);

            this._stateCallbacks.forEach(v=>{
                v({status: WS.STATE_CLOSED, data: null})
            })
        };

        ws.onerror = (e)=>{
            console.error('ws', 'error');
            console.error(e);
        };

        this.ws = ws;
    }

    disconnect() {
        if(this.ws!==null) {
            this.ws.close();
        }
    }
}