
export default class Session{

  static update(authToken){
    if(authToken===null){
      delete localStorage.authToken;
      delete localStorage.jwtSession;
    } else {
      localStorage.authToken = authToken;
      localStorage.jwtSession = decodeURIComponent(
          window.atob(localStorage.authToken.split('.')[1]
              .replace(/-/g, '+')
              .replace(/_/g, '/'))
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
      );
    }
  }

  static read(){
    if(typeof localStorage.jwtSession==='undefined')
      return null;
    return JSON.parse(localStorage.jwtSession);
  }
  static token(){
    if(window.location.host==='localhost:80801')
      return 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcHAiLCJwcm9qZWN0IjoiYzltIiwidXNlcklkIjowLCJ1c2VyRnVsbG5hbWUiOiJUZXN0IFVzZXIiLCJ1c2VyUGVybXMiOlsiZmluYW5jZXMiXSwiZXhwIjoxNzEyNjc2NzYwfQ.0qKW03bNVOZezGI69i6KDj8njljWE4RjqGP1iCx_kZGiP_DZh2MvYtlVMakpa49z-y1ul3DlFsB-_lwPFVn1Sw';
    if(typeof localStorage.authToken==='undefined')
      return null;
    return localStorage.authToken;
  }
}