<template>
  <div>
    <v-sheet
        class="pl-2 mr-2 rounded-bl rounded-br overflow-hidden transition-ease-in-out align-content-center"
        ref="box"
        :max-width="1100"
        height="60"
        :style="'background-color:'+(callEntrys.length>0 ? 'rgba(255,255,255,0.1)': 'rgba(0,0,0,0)')"
    >
      <v-sheet ref="box1" color="transparent" :width="calcWidth()">
        <call-widget
            v-for="v in callEntrys"
            :key="'callEntryStateEvent_'+v"
            :ref="'callEntryStateEvent_'+v"
            :callTargets="callTargets"
            @minimize="minimize"
            @close="(v)=>callEntrys = callEntrys.filter(v1=>v1!==v)"
        />
        <v-btn
            v-if="callEntrys.length>0"
            width="42" min-width="42" height="42"
            style="background-color: rgba(255,255,255,0.1)"
            depressed class="mr-2 d-inline-block"
            @click="closeDisconnect()"
        >
          <v-icon>mdi-notification-clear-all</v-icon>
        </v-btn>
        <call-widget-request :open.sync="openRequest" @minimize="minimize()"/>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import CallWidget from "@/views/Call/CallWidget.vue";
import CallWidgetRequest from "@/views/Call/CallWidgetRequest.vue";
import {Entity_CallResult, Entity_CallTarget} from "../../../EntityStoreCacheService";

export default {
  components: {CallWidgetRequest, CallWidget},
  data: () => ({
    callEntrys:[],
    callGroups:[],
    opens:[],
    openRequest:false,
    callTargets:[],
  }),
  mounted() {
    new Entity_CallTarget()
        .selects(Entity_CallTarget.id)
        .selects(Entity_CallTarget.name)
        .selects(Entity_CallTarget.comment)
        .selects([Entity_CallTarget.callResults, Entity_CallResult.id].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.name].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.type].join('.'))
        .selects([Entity_CallTarget.callResults, Entity_CallResult.comment].join('.'))
        .order(Entity_CallTarget.sort, true)
        .page(1)
        .onPage(1000)
        .addStateCallback((v)=>{
          if(v.success) this.callTargets = v.items;
        })
        .request()
  },
  methods: {
    event(data){
      let arr = this.callEntrys;
      if(arr.filter(v=>v===data.callEntryId).length===0)
        arr.unshift(data.callEntryId);
      /*
      let arr2 = this.callGroups.filter(v=>v.id===data.callGroupId);
      if(arr2.length===0) this.callGroups.push({id:data.callGroupId, callEntryId: data.callEntryId});
      arr2.filter(v=>v.callEntryId!==data.callEntryId).forEach(v1=>{
        arr = arr.filter(v=>v!==v1.callEntryId)
      })

       */
      this.callEntrys = arr;

      this.$nextTick(()=>{
        Object.keys(this.$refs).filter(v=>v==='callEntryStateEvent_'+data.callEntryId).forEach(v => {
          if(this.$refs[v].length>0) this.$refs[v][0].event(data)
        })
        this.scrollLeftMax();
      });
    },
    minimize(callEntryId=null){
      this.openRequest=false;
      this.$nextTick(()=>{
        if(callEntryId===null){
          Object.keys(this.$refs).filter(v=>v.includes('callEntryStateEvent_')).forEach(v => {
            if(this.$refs[v].length>0) this.$refs[v][0].minimize()
          })
        } else {
          Object.keys(this.$refs).filter(v=>v.includes('callEntryStateEvent_') && v!=='callEntryStateEvent_'+callEntryId).forEach(v => {
            if(this.$refs[v].length>0) this.$refs[v][0].minimize()
          })
        }
      });
    },
    closeDisconnect(){
      this.$nextTick(()=>{
        Object.keys(this.$refs).filter(v=>v.includes('callEntryStateEvent_')).forEach(v => {
          if(this.$refs[v].length>0){
            if(this.$refs[v][0].canClose()){
              this.callEntrys = this.callEntrys.filter(v1=>v1!==parseInt(v.split('_')[1]))
            }
          }
        })
      });
    },
    scrollLeftMax(){
      this.$nextTick(()=>{
        this.$refs.box.$el.scrollLeft = 1000000;
      });
    },
    calcWidth(){
      let v = 74+8;
      v+=this.callEntrys.length*300;
      if(this.callEntrys.length>0)
        v+=42+8;
      return v;
    }
  }
}
</script>

