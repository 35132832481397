import { Model } from '@vuex-orm/core'
import ComBranch from "@/model/ComBranch";
import ComPlace from "@/model/ComPlace";
import ServiceCat from "@/model/ServiceCat";
import UserSpec from "@/model/UserSpec";

export default class ComDiv extends Model {
    static entity = 'comDivs'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),

            comBranch_id: this.attr(null),
            comBranch: this.belongsTo(ComBranch, 'comBranch_id'),

            userSpecs: this.hasMany(UserSpec, 'userSpec_id'),

            comPlaces: this.hasMany(ComPlace, 'comDiv_id'),

            serviceCats: this.hasMany(ServiceCat, 'comDiv_id'),
        }
    }
}