<template>
  <div class="d-inline-block">
    <v-menu
        v-model="localOpen"
        rounded="lg"
        offset-y
        style="z-index:1000"
        :close-on-click="false"
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-sheet color="transparent" width="300" class="pr-2">
          <v-btn
              v-bind="attrs"
              v-on="on"
              height="42"
              :class="'mr-2 full-width text-left text-capitalize '+(localOpen ? ' v-btn--active ' : '')+(blink() ? 'blinkBackground ' : '')"
              style="background-color: rgba(255,255,255,0.1)"
              depressed
          >
            <div v-if="callEntryState===null">
              <v-progress-circular indeterminate size="18" width="2"/>
            </div>
            <v-sheet v-else color="transparent" width="300" class="px-2">
              <v-row no-gutters>
                <v-col class="align-content-center pr-1" cols="auto">
                  <call-entry-state :call-entry="callEntryState.callEntry" :blink="blink()"/>
                </v-col>
                <v-col class="text-overflow pr-2">
                  <div class="caption">
                    <call-direction :call-group="callEntryState.callEntry.callGroup"/>
                    /
                    <call-status :call-entry="callEntryState.callEntry"/>
                  </div>
                  <div class="body-2 font-weight-medium text-overflow">
                    <span v-if="callEntryState.callEntry.userFrom!==null && $store.state.user.id!==callEntryState.callEntry.userFrom.id">
                      {{$tools.isEmpty(callEntryState.callEntry.userFrom.lastName) ? '' : callEntryState.callEntry.userFrom.lastName + ' '}}
                      {{$tools.isEmpty(callEntryState.callEntry.userFrom.firstName) ? '' : callEntryState.callEntry.userFrom.firstName + ' '}}
                      {{$tools.isEmpty(callEntryState.callEntry.userFrom.secondName) ? '' : callEntryState.callEntry.userFrom.secondName}}
                    </span>
                    <span v-else-if="callEntryState.callEntry.userTo!==null && $store.state.user.id!==callEntryState.callEntry.userTo.id">
                      {{$tools.isEmpty(callEntryState.callEntry.userTo.lastName) ? '' : callEntryState.callEntry.userTo.lastName + ' '}}
                      {{$tools.isEmpty(callEntryState.callEntry.userTo.firstName) ? '' : callEntryState.callEntry.userTo.firstName + ' '}}
                      {{$tools.isEmpty(callEntryState.callEntry.userTo.secondName) ? '' : callEntryState.callEntry.userTo.secondName}}
                    </span>
                    <span v-else>{{companion.phoneNumber}}</span>
                  </div>
                </v-col>
                <v-col class="align-content-center px-2" cols="auto">
                  <v-btn icon small @click="$emit('close', callEntryId)"><v-icon size="26">mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-btn>
        </v-sheet>
      </template>

      <v-sheet v-if="callEntryState!==null" width="680">
        <v-row class="mt-2 mx-4" no-gutters>
          <v-col>
            <div class="caption">
              <call-direction :call-group="callEntryState.callEntry.callGroup"/>
              на
              <span v-if="callEntryState.callEntry.userFrom!==null && $store.state.user.id!==callEntryState.callEntry.userFrom.id">
                {{$tools.userFullName(callEntryState.callEntry.userFrom)}}
              </span>
              <span v-else-if="callEntryState.callEntry.userTo!==null && $store.state.user.id!==callEntryState.callEntry.userTo.id">
                {{$tools.userFullName(callEntryState.callEntry.userTo)}}
              </span>
              <span v-else>
                Неизвестный
              </span>
            </div>
            <div class="caption">
              <call-status :call-entry="callEntryState.callEntry" fullText/>
              {{$tools.isEmpty(callEntryState.callEntry.callGroup.disconnectReasonMessage) ? '' : ' - '+callEntryState.callEntry.callGroup.disconnectReasonMessage}}
            </div>
          </v-col>
          <v-col cols="auto" class="pt-2">
            <v-btn depressed small @click="localOpen=false" class="mr-2"><v-icon small class="mr-2">mdi-eye-off</v-icon>Свернуть</v-btn>
            <v-btn depressed small @click="$emit('close', callEntryId)"><v-icon small class="mr-2">mdi-close</v-icon>Закрыть</v-btn>
          </v-col>
        </v-row>
        <div class="px-4 mb-2 py-2">
          <call-form :call-group="callEntryState.callEntry.callGroup" :call-targets="callTargets" @close="localOpen=false">
            <template v-slot:widget>
              <v-sheet height="40" class="mb-4">
                <call-entry-state :call-entry="callEntryState.callEntry" :phoneNumber="companion.phoneNumber" :blink="blink()"/>
              </v-sheet>
            </template>
            <template v-slot:actions>
              <v-btn @click="localOpen=false;$router.push('/call/')" depressed block small class="mt-2">Журнал</v-btn>
            </template>
          </call-form>
        </div>
      </v-sheet>
    </v-menu>
  </div>

</template>

<script>


import {
  Entity_CallEntry,
  Entity_CallEntryState,
  Entity_CallGroup, Entity_CallResult, Entity_CallTarget,
  Entity_User, Entity_UserProfile
} from "../../../EntityStoreCacheService";
import CallEntryState from "@/views/Call/CallEntryState.vue";
import CallStatus from "@/views/Call/CallStatus.vue";
import CallDirection from "@/views/Call/CallDirection.vue";
import State from "@/plugins/state";
import CallForm from "@/views/Call/CallForm.vue";

export default {
  components: {
    CallForm,
    CallDirection,
    CallStatus,
    CallEntryState},
  props:['callTargets'],
  data: () => ({
    state: new State(),
    callEntryId:null,
    companion:null,
    callEntryState:null,
    localOpen:false,
  }),
  watch:{
    localOpen(){
      if(this.localOpen) this.$emit('minimize', this.callEntryId);
    }
  },
  methods: {
    event(v){
      this.callEntryId = v.callEntryId;
      this.companion = v.companion;
      this.$tools.throttle(()=>{
        new Entity_CallEntryState()
            .selects(Entity_CallEntryState.id)
            .selects(Entity_CallEntryState.timestamp)

            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userFrom, Entity_User.id].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userFrom, Entity_User.lastName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userFrom, Entity_User.firstName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userFrom, Entity_User.secondName].join('.'))

            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userTo, Entity_User.id].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userTo, Entity_User.lastName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userTo, Entity_User.firstName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.userTo, Entity_User.secondName].join('.'))

            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.fromPhoneNumber].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.toPhoneNumber].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.state].join('.'))

            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.id].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.toPhoneNumber].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.fromPhoneNumber].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.callDirection].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.status].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.disconnectReason].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.disconnectReasonMessage].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.comment].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.callRecordFile].join('.'))


            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.callTarget, Entity_CallTarget.id].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.callResult, Entity_CallResult.id].join('.'))

            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.id].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.firstName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.lastName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.secondName].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.userProfile, Entity_UserProfile.gender].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClient, Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
            .selects([Entity_CallEntryState.callEntry, Entity_CallEntry.callGroup, Entity_CallGroup.userClientState].join('.'))

            .filterAnd([Entity_CallEntryState.entity, Entity_CallEntryState.callEntry, Entity_CallEntry.id].join('.')+'=?',this.callEntryId)
            //.selects(Entity_CallGroup.comment)
            //.selects([Entity_CallGroup.salaryTemplateServices,Entity_SalaryTemplateService.id].join('.'))
            .page(1)
            .onPage(1)
            .addStateCallback((v)=> this.state.setRequestBuilderState(v))
            .addStateCallback((v)=>{
              if(v.success)
                this.callEntryState = v.items[0];
            })
            .request()
      }, 500, 'callWidgetLoad_'+this.callEntryId)
    },
    minimize(){
      this.localOpen = false;
    },
    canClose(){
      return this.callEntryState?.callEntry.callGroup!=='progress';
    },
    blink(){
      if(this.callEntryState!==null){
        if(this.callEntryState.callEntry.state==='Appeared' && this.callEntryState.callEntry.callGroup.status==='progress' && this.callEntryState.callEntry.callGroup.callDirection===1) return true;
        //if(this.callEntryState.callEntry.state==='Connected') return true;
        //if(this.callEntryState.callEntry.state==='OnHold') return true;
      }
      return false;
    }
  }
}
</script>

