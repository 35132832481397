import axios from "axios";
import Vue from "vue";
import Session from "@/plugins/session";

export default class GraphqlApi {
  loading = false
  success = false
  error = false

  dialogOpen = false;

  request(url, query, vars=null, success=null, error=null, useCache=false, jwtSession=false){
    this.loading = true
    this.success = false
    this.error = false

    let cacheKey = useCache ?
      (url+":"+query+":"+JSON.stringify(vars)).replaceAll('\n', '').replaceAll(' ', '') :
      null;
    if(cacheKey!==null && typeof Vue.prototype.$apiCache[cacheKey]!=='undefined'){
      this.loading = false;
      this.success = true;
      success(Vue.prototype.$apiCache[cacheKey])
    } else {
      let headers = {};
      if(jwtSession)
        headers["X-Jwt-Token"] = Session.token();

      axios.post(url, {
        query: query.replace('  ', ''),
        variables: vars === null ? {} : vars,
      }, {
        withCredentials: true,
        headers: headers
      })
        .then((r) => {
          this.loading = false;

          if (typeof r.data.data === 'undefined' || r.data.data===null) {
            this.error = true;
            if (error !== null) error(r.data)
          } else {
            this.success = true;
            if (success !== null) {
              if(useCache!==null)
                Vue.prototype.$apiCache[cacheKey] = r.data.data;
              success(r.data.data)
            }
          }
        })
        .catch(e => {
          this.loading = false;
          this.error = true;

          console.error(e);
          if (error !== null)
            error(e);
        });
    }
  }

  requestSingleModelMultipleDataV1(field, model=null, url, query, vars, error=null, gen=null, useCache=null){
    this.request(
      url,
      query,
      vars,
      (d)=>{
        if(gen!==null)
          d[model].forEach((v,k)=>{
            d[model][k] = gen(v);
          });

        field.splice(0);
        field.push(...d[model]);
      },
      error,
      useCache
    );
  }

  requestSingleModelSingleDataV1(field, model=null, url, query, vars, error=null, gen=null, useCache=null){
    this.request(
      url,
      query,
      vars,
      (d)=>{
        console.log(d);
        if(gen!==null)
          d[model][0] = gen(d[model][0]);

        Object.assign(field, d[model][0])
      },
      error,
      useCache
    );
  }

  requestSingleModelMultipleDataV2(field, url, query, vars, error=null, gen=null, useCache=null){
    this.request(
      url,
      query,
      vars,
      (d)=>{
        let data = d.users;
        if(gen!==null)
          data.items.forEach((v,k)=>{
            data.items[k] = gen(v);
          });

        Object.assign(field, data)
      },
      error,
      useCache
    );
  }
}



