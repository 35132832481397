import { Model } from '@vuex-orm/core'
import User from "@/model/User";
import ComPlace from "@/model/ComPlace";
import UserSpec from "@/model/UserSpec";

export default class Visit extends Model {
    static entity = 'visits'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            status: this.attr(null),
            paySummary: this.attr(null),
            begin: this.attr(null),
            end: this.attr(null),
            mark: this.attr(null),
            comment: this.attr(null),
            commentSpec: this.attr(null),
            promoCode: this.attr(null),
            clubCard: this.attr(null),
            isSecond: this.attr(null),

            comPlace_id: this.attr(null),
            comPlace: this.belongsTo(ComPlace, 'comPlace_id'),

            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),

            userSpec_id: this.attr(null),
            userSpec: this.belongsTo(UserSpec, 'userSpec_id'),
        }
    }
}