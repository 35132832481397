<template>
  <div>
    <div v-if="type==='text' || typeof type==='undefined'" class="typeText mt-1">
      <v-text-field
        :label="label"
        v-model="valueLocal"
        outlined dense hide-details="auto"
      >
        <template v-slot:append>
          <v-progress-circular v-if="saveState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
          <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
          <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
        </template>
      </v-text-field>
    </div>
    <div v-if="type==='textarea' || typeof type==='undefined'" class="typeTextarea mt-1">
      <v-textarea
        :label="label"
        v-model="valueLocal"
        ref="textarea"
        outlined dense hide-details="auto" auto-grow rows="1"
      >
        <template v-slot:append>
          <v-progress-circular v-if="saveState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
          <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
          <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
        </template>
      </v-textarea>
    </div>
    <div v-if="type==='date'" class="typeDate mt-1">
      <v-menu
          v-model="isEdit"
          :close-on-content-click="false"
          offset-y
          min-width="300"
          max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-text-field
                readonly
                :clearable = "typeof clearable !== 'undefined'"
                @click:clear="valueLocal=null"
                :label="label"
                :value="$tools.date(valueLocal)"
                outlined dense hide-details="auto"
            >
              <template v-slot:append>
                <v-progress-circular v-if="saveState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
                <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
                <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
              </template>
            </v-text-field>
          </div>
        </template>
        <v-date-picker
            :first-day-of-week="1"
            full-width
            v-model="valueLocal"
            @change="isEdit=false"
            no-title
        />
      </v-menu>
    </div>
    <div v-if="type==='dateTime'" class="typeDateTime mt-1">
      <v-text-field
          :label="label"
          v-model="valueLocal"
          @change="save()"
          v-mask="'##.##.#### ##:##'"
          outlined dense hide-details="auto"
      >
        <template v-slot:append>
          <v-progress-circular v-if="saveState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
          <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
          <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
        </template>
      </v-text-field>
    </div>
    <label-view v-if="type==='datePicker'">
        <template v-slot:content>
            <v-menu v-model="datePickerIsOpen" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs" v-on="on"
                  :value="$tools.dateTimeFormat(valueLocal, 'DD.MM.YYYY hh:mm')"
                  :label="label" class="mt-2 mb-4" outlined dense hide-details="auto" readonly
                >
                  <template v-slot:append>
                  <v-progress-circular v-if="saveState.isLoading" indeterminate class="mt-1 mr-1" color="primary" size="16" width="2"/>
                  <v-icon v-if="saveState.isSuccess" color="success">mdi-check</v-icon>
                  <v-icon v-if="saveState.isError" color="error">mdi-information-outline</v-icon>
                </template> 
              </v-text-field>  
            </template>
            <v-date-picker :first-day-of-week="1" v-model="datePickerValue" color="primary" show-current class="mb-4" no-title  @change="datePickerIsOpen=false;datePickerChange();"/>
            </v-menu>
        </template>
    </label-view>
    <div v-if="type==='selectStatus'" class="typeSelectStatus">
      <v-menu
          v-if="!$tools.isEmpty(valueLocal) && selectValues.length>0"
          v-model="isEdit"
          :close-on-content-click="false"
          offset-y
          min-width="300"
          max-width="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div class="mb-1" style="font-size: 16px;transform: scale(0.75);margin-top:-4px;margin-left:-20px">{{ label }}</div>
            <div class="statusContainer">
              <v-chip v-if="saveState.isInit || saveState.isSuccess" :color="selectValues.filter(v=>v.id===valueLocal)[0].color" small dark class="status white--text clickable" pill>
                <span class="name">{{selectValues.filter(v=>v.id===valueLocal)[0].name}}</span>
                <v-progress-circular v-if="saveState.isLoading" indeterminate class="ml-2" color="primary" size="18" width="2"/>
              </v-chip>
              <v-chip v-if="saveState.isLoading" color="grey lighten-2" small dark class="status white--text clickable" pill>
                <v-progress-circular v-if="saveState.isLoading" indeterminate color="primary" size="16" width="2"/>
              </v-chip>
              <v-chip v-if="saveState.isError" color="grey lighten-2" small dark class="status white--text clickable" pill>
                <v-icon v-if="saveState.isError" color="error" size="18">mdi-information-outline</v-icon>
              </v-chip>
            </div>
          </div>
        </template>
        <v-list class="pa-0">
          <v-list-item v-for="selectValue in selectValues" :key="selectValue.id" link class="text--primary" @click="valueLocal=selectValue.id;isEdit=false">
            <v-list-item-title>
              <v-avatar :color="selectValue.color" size="14" class="v-overlay--absolute" style="margin-top:1px"/>
              <div class="ml-6">{{selectValue.name}}</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import State from "@/plugins/state";
import moment from "moment";
import LabelView from "@/components/LabelView.vue";

export default {
  components: {LabelView},
  props: [
    'label',
    'value',
    'url',
    'field',
    'type',
    'selectValues',
    'fromFormat',
    'clearable'
  ],
  data:()=>({
    isEdit:false,
    valueLocal:null,
    isValueLocalInit:false,
    saveState:new State(),
    datePickerIsOpen: false,
    datePickerValue: null,
  }),
  mounted(){
    if(this.type==='textarea') {
      setTimeout(()=>{this.$refs.textarea.calculateInputHeight();}, 100);
      this.valueLocal = this.value;
      
    } else if(this.type==='dateTime') {
      this.valueLocal = this.$tools.dateTimeFormat(this.value, 'DD.MM.YYYY HH:mm', this.fromFormat);

    } else if(this.type === 'datePicker') {
      this.valueLocal = this.$tools.dateTimeFormat(this.value, 'YYYY-MM-DD HH:mm');
      this.datePickerValue = this.$tools.dateTimeFormat(this.value, 'YYYY-MM-DD');

    } else {
      if(this.value === null) {
        this.isValueLocalInit = true;
      } else {
        this.valueLocal = this.value;
      }
    }
    this.saveState.stateInit();
  },
  watch:{
    // value(){
    //   this.valueLocal = this.value;
    // },
    valueLocal(){
      this.save();
    }
  },
  methods:{
    save(){
      if(!this.isValueLocalInit){
        this.isValueLocalInit = true;
        return 0;
      }

      if(
          this.type==='selectStatus'
      ){
        this.saveState.stateLoading();
        this.request();
      } else {
        this.saveState.stateLoading();
        this.$tools.throttle(()=>{
          this.request();
        });
      }
    },

    request(){
      if(this.type==='dateTime' && this.valueLocal.length!==16){
        this.saveState.stateError();
        return;
      }
      let data = {};
      data[this.field] =
          this.type==='dateTime' ? moment(this.valueLocal, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm')+':00' :
          this.valueLocal;
      axios.put(this.url, data, {withCredentials: true})
          .then(() =>{
            this.saveState.stateSuccess();
            this.$emit('updated', this.valueLocal);
          })
          .catch(() =>{
            this.saveState.stateError();
          });
    },
    datePickerChange() {
      let currentTime = this.$tools.dateTimeFormat(moment(), 'HH:mm:00');
      this.valueLocal = this.$tools.dateTimeFormat(this.datePickerValue+' '+currentTime, 'YYYY-MM-DD HH:mm:ss');
    }
  }
}
</script>


<style lang="scss">
.typeText .v-input__control,
.typeTextarea .v-input__control,
.typeDate .v-input__control,
.typeDateTime .v-input__control{
  background-color: #FFF;
}
.typeSelectStatus{
  .statusContainer {
    display: inline-block;
    text-align: center;
    .status{
      width: 100px !important;
      .v-chip__content{
        margin: auto !important;
      }
    }
  }
}
</style>
