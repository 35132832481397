import { Model } from '@vuex-orm/core'
import Visit from "@/model/Visit";
import ComRoom from "@/model/ComRoom";
import UserSpecPlan from "@/model/UserSpecPlan";
import ComDiv from "@/model/ComDiv";

export default class ComPlace extends Model {
    static entity = 'comPlaces'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),

            comRoom_id: this.attr(null),
            comRoom: this.belongsTo(ComRoom, 'comRoom_id'),

            comDiv_id: this.attr(null),
            comDiv: this.belongsTo(ComDiv, 'comDiv_id'),

            visits: this.hasMany(Visit, 'comPlace_id'),

            userSpecPlans: this.hasMany(UserSpecPlan, 'userSpecPlan_id'),
        }
    }
}