<template>
  <span class="text">
    <span v-if="callEntry.callGroup.status==='fail'">Не отвечено</span>
    <span v-else-if="callEntry.callGroup.status==='failNonWork'">Не отвечено{{typeof fullText==='undefined' ? '' : ' (нерабочее время)'}}</span>
    <span v-else-if="callEntry.callGroup.status==='success'">Успешно</span>
    <span v-else-if="callEntry.state==='Appeared'">Звонок</span>
    <span v-else-if="callEntry.state==='Connected'">На линии</span>
    <span v-else-if="callEntry.state==='OnHold'">Удержание</span>
    <span v-else-if="callEntry.state==='Disconnected'">Не отвечено</span>
  </span>

</template>

<script>
export default {
  props:['callEntry','fullText']
}
</script>

<style scoped>
.text span{
  text-transform: initial !important
}
</style>

