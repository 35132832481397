import { Model } from '@vuex-orm/core'

export default class VisitStatus extends Model {
    static entity = 'visitStatuses'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),
            color: this.attr(null),
        }
    }
}