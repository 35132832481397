<template>
  <v-menu rounded="lg" offset-y style="z-index:10000" min-width="200" max-width="200">
    <template v-slot:activator="{ attrs, on }">
      <v-badge
          offset-y="23"
          offset-x="23"
          bottom
          color="transparent"
      >
        <template v-slot:badge>
          <v-avatar v-if="wsStatus===WS.STATE_CONNECTING" color="success" width="14" height="14" class="avatar">
            <v-progress-circular indeterminate size="14" width="2"/>
          </v-avatar>
          <v-avatar v-else-if="wsStatus===WS.STATE_OPEN" color="success" width="14" height="14" class="avatar">
            <v-progress-circular value="100" size="14" width="2"/>
          </v-avatar>
        </template>
        <v-btn v-bind="attrs" v-on="on" icon class="mr-0">
          <v-avatar size="36" color="white">
            <img v-if="$store.state.user.imageUrl!==null" :src="$store.state.user.imageUrl">
            <v-icon v-else large>mdi-account-circle</v-icon>
          </v-avatar>
        </v-btn>
      </v-badge>
    </template>
    <v-list>
      <v-list-item v-if="$store.state.session!==null">
        <v-list-item-title class="pb-2">
          <div class="subtitle-2 text-wrap" v-html="$store.state.session.userFullname">
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item link @click="$tools.openUrl('/panel/user/client/'+$store.state.session.userId+'/')">
        <v-list-item-title>Профиль</v-list-item-title>
      </v-list-item>
      <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
        <v-list-item-title>Тема: {{$vuetify.theme.dark ? 'Светлая' : 'Темная'}}</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="logout">
        <v-list-item-title>Выйти</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import Session from "@/plugins/session";
import WS from "@/plugins/ws";

export default {
  computed: {
    WS() {
      return WS
    }
  },
  props:['wsStatus'],
  methods:{
    logout(){
      axios.get('/api/user/logout/', {withCredentials: true})
          .then(()=>{
            if(typeof window.ws!=='undefined'){
              window.ws.close();
              delete window.ws;
            }
            Session.update(null);
            this.$store.state.user = null;
            this.$store.state.session = null;
          })
    }
  }
};
</script>

<style scoped>
.avatar{
  min-height:0 !important;
  min-width:0 !important;
}
</style>