import { Model } from '@vuex-orm/core'
import Service from "@/model/Service";
import Com from "@/model/Com";
import ComDiv from "@/model/ComDiv";

export default class ServiceCat extends Model {
    static entity = 'serviceCats'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            status: this.attr(null),
            name: this.attr(null),
            sort: this.attr(null),
            isProgram: this.attr(null),
            price: this.attr(null),
            des: this.attr(null),
            isShowForClient: this.attr(null),

            com_id: this.attr(null),
            com: this.belongsTo(Com, 'com_id'),
            serviceCatServiceCount: this.attr(null),

            comDiv_id: this.attr(null),
            comDiv: this.belongsTo(ComDiv, 'comDiv_id'),

            serviceCats: this.hasMany(ServiceCat, 'serviceCatParent_id'),
            serviceCatParent_id: this.attr(null),
            serviceCat: this.belongsTo(ServiceCat, 'serviceCatParent_id'),

            services: this.hasMany(Service, 'serviceCat_id'),
        }
    }
}