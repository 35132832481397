import { Model } from '@vuex-orm/core'
import UserSpec from "@/model/UserSpec";

export default class Spec extends Model {
    static entity = 'specs'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),
            color: this.attr(null),

            userSpecs: this.hasMany(UserSpec, 'spec_id'),
        }
    }
}