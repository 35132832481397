<template>
  <div class="text-center py-4">
    <v-icon class="mb-2" color="grey">
      {{typeof icon==='undefined' ? 'mdi-information-outline' : icon}}
    </v-icon>
    <div class="muted1">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon']
}
</script>
