import { Model } from '@vuex-orm/core'
import ComPlace from "@/model/ComPlace";
import ComFloor from "@/model/ComFloor";

export default class ComRoom extends Model {
    static entity = 'comRooms'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            sid: this.attr(null),
            name: this.attr(null),

            comFloor_id: this.attr(null),
            comFloor: this.belongsTo(ComFloor, 'comFloor_id'),

            comPlaces: this.hasMany(ComPlace, 'comRoom_id'),
        }
    }
}