import { Model } from '@vuex-orm/core'

export default class UserProfile extends Model {
    static entity = 'userProfiles'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            gender: this.attr(null),
            birth: this.attr(null),
            phone1: this.attr(null),
            email: this.attr(null),
            comment: this.attr(null),
            whatsapp: this.attr(null),
            telegram: this.attr(null),
            instagram: this.attr(null),
            vk: this.attr(null),
            promoSource: this.attr(null),
            resAddrStreet: this.attr(null),
            resAddrEntrance: this.attr(null),
            resAddrIntercom: this.attr(null),
            resAddrFloor: this.attr(null),
            resAddrRoom: this.attr(null),
            pasSeries: this.attr(null),
            pasNumber: this.attr(null),
            pasBy: this.attr(null),
            pasDate: this.attr(null),
            pasAddrStreet: this.attr(null),
            pasAddrRoom: this.attr(null),
            inn: this.attr(null),
            medCardNumber: this.attr(null),
            medCardStore: this.attr(null),
            medCardStorePosition: this.attr(null)
        }
    }
}