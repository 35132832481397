<template>
  <div class="d-inline-block">
    <v-menu
        v-model="localOpen"
        rounded="lg"
        offset-y
        style="z-index:1000"
        :close-on-click="false"
        :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
            v-bind="attrs" v-on="on"
            width="74" min-width="74" height="42"
            class="mr-2 d-inline-block"
            style="background-color: rgba(255,255,255,0.1)"
            depressed
        >
          <div v-if="loadState.isLoading" class="position-relative" style="top:-22px;left:-22px">
            <v-sheet class="position-absolute align-content-center" width="42" height="42" color="transparent">
              <v-progress-circular indeterminate size="34" width="2"/>
            </v-sheet>
            <v-sheet class="position-absolute align-content-center" width="42" height="42" color="transparent">
              <v-icon size="18">mdi-phone-dial</v-icon>
            </v-sheet>
          </div>
          <div v-else>
            <v-icon size="18">mdi-phone-dial</v-icon>
            <span class="ml-2">{{phoneCaller}}</span>
          </div>
        </v-btn>
      </template>
      <v-sheet class="pa-4" width="300">
        <v-row no-gutters>
          <v-col cols="12" class="mb-3">
            <v-row no-gutters>
              <v-col class="pr-1">
                <v-btn depressed block small @click="localOpen=false"><v-icon small class="mr-2">mdi-eye-off</v-icon>Свернуть</v-btn>
              </v-col>
              <v-col class="pl-1">
                <v-btn depressed block small @click="localOpen=false;$router.push('/call/')"><v-icon small class="mr-2">mdi-eye-off</v-icon>Журнал</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="phoneCaller!==null" cols="auto">
            <v-sheet width="210" class="pr-3">
              <v-text-field
                  v-model="phone"
                  @click:clear="contactsSearch()"
                  height="40"
                  type="phone" outlined clearable dense hide-details="auto"
              >
                <template v-slot:prepend-inner>
                  <v-sheet v-if="contactsSearchState.isLoading" style="margin-top:3px" width="20">
                    <v-progress-circular  indeterminate color="primary" size="18" width="2"/>
                  </v-sheet>
                  <v-sheet v-else style="margin-top:2px" width="20">
                    <v-icon size="22">mdi-magnify</v-icon>
                  </v-sheet>
                </template>
              </v-text-field>
            </v-sheet>
          </v-col>
          <v-col v-if="phoneCaller!==null">
            <v-btn @click="callRequest(phone)" height="40" depressed color="green" block dark>
              <v-icon>mdi-phone</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="phoneCaller!==null" cols="12">
            <alert-view
                v-if="$tools.isEmpty(phone) || (!$tools.isEmpty(phone) && contactsSearchState.isLoading)"
                text="Введите текст для поиска"
                icon="mdi-information-outline"
            />
            <alert-view
                v-else-if="contacts.length===0"
                text="Не найдено"
                icon="mdi-information-outline"
            />
            <v-sheet v-else-if="contacts.length>0" class="my-2">
              <v-list class="pa-0" dense>
                <v-list-item v-for="(contact, i) in contacts" :key="'contact'+i">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{contact.name}}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="mt-n1">
                    <v-btn icon depressed :href="contact.url" target="_blank">
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>
          <v-col v-if="phoneCaller===null" cols="12">
            <alert-view
                text="Укажите номер телефона который вы хотите использовать"
                icon="mdi-information-outline"
            />
          </v-col>
          <v-col cols="12">
            <field-view
                label="Номер телефона в организации" :value="phoneCaller"
                url="/api/caller/phoneCompany/" field="phoneCompany" type="text"
                @updated="(v)=>{phoneCaller=v}"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>
  </div>

</template>

<script>

import axios from "axios";
import Api from "@/Api";
import FieldView from "@/components/FieldView.vue";
import AlertView from "@/components/AlertView.vue";
import State from "@/plugins/state";

export default {
  components: {AlertView, FieldView},
  props:['open'],
  data: () => ({
    localOpen:false,
    loadState:new State(),
    phoneCaller:null,
    phone:null,
    contactsSearchState: new State(),
    contacts:[],
  }),
  mounted() {
    this.userPhoneLoad();
  },
  watch:{
    open(){
      this.localOpen = this.open;
    },
    localOpen(){
      if(this.localOpen) this.$emit('minimize');
      this.$emit('update:open', this.localOpen);
    },
    phone(){
      this.contactsSearch();
    },
    '$store.state.callPhone'(){
      if(!this.$tools.isEmpty(this.$store.state.callPhone)){
        this.callRequest(this.$store.state.callPhone);
        this.$store.state.callPhone = null;
      }
    },
  },
  methods:{
    userPhoneLoad(){
      if(this.$store.state.user===null){
        this.phoneCaller = null;
      } else {
        this.loadState.stateLoading();
        axios
            .get(Api.host+'/user/'+this.$store.state.user.id+'/')
            .then(r =>{
              this.phoneCaller = r.data.userProfile.phoneCompany
              this.loadState.stateSuccess();
            })
            .catch(()=>{
              this.loadState.stateError();
            })
      }
    },
    callRequest(phoneRequest=null){
      this.phone = phoneRequest;
      if(!this.$tools.isEmpty(this.phone)) {
        axios.post(Api.host + '/caller/request/', {
          from: this.phoneCaller,
          to: phoneRequest
        })
            .then(() => {
              this.phone = null;
              this.localOpen = false;
              this.$store.commit('alertSuccess', 'Исходящий звонок выполняется на номер: ' + phoneRequest);
            })
            .catch(e => {
              Api.responseError(e);
            });
      }
    },
    contactsSearch(){
      this.contactsSearchState.stateLoading();
      this.$tools.throttle(()=>{
        if(this.$tools.isEmpty(this.phone)){
          this.contacts = [];
          this.contactsSearchState.stateSuccess();
        } else {
          axios.get(Api.host+'/caller/contactFind/?phone='+encodeURIComponent(this.phone))
              .then(r =>{
                this.contacts = r.data;
                this.contactsSearchState.stateSuccess();
              })
              .catch(e =>{
                this.contactsSearchState.stateError();
                Api.responseError(e);
              });
        }
      });
    }
  }
}
</script>

