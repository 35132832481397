import { Model } from '@vuex-orm/core'
import ServiceCat from "@/model/ServiceCat";

export default class Service extends Model {
    static entity = 'services'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            status: this.attr(null),
            name: this.attr(null),
            sort: this.attr(null),
            code: this.attr(null),
            codeType: this.attr(null),
            priceExpend: this.attr(null),
            priceExec: this.attr(null),
            priceTotal: this.attr(null),
            des: this.attr(null),
            duration: this.attr(null),
            comment: this.attr(null),
            isShowForClient: this.attr(null),
            isVisitCreateForClient: this.attr(null),
            isPriceLock: this.attr(null),
            priceDiscount: this.attr(null),
            isid: this.attr(null),

            serviceCat_id: this.attr(null),
            serviceCat: this.belongsTo(ServiceCat, 'serviceCat_id'),
        }
    }
}