<template>
  <v-menu
      v-model="state.isModalOpen"
      rounded="lg"
      offset-y style="z-index:1001" :close-on-content-click="false"
  >
    <template v-slot:activator="{ attrs, on }">

        <v-btn v-on="on" icon class="mr-2">
          <v-badge :color="notifs.length>0 ? 'red' : ''" :content="notifs.length" offset-y="10" >
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>

    </template>
    <v-sheet width="220">
      <v-row no-gutters>
        <v-col cols="12">

        </v-col>
        <v-col cols="12">
          <div v-if="notifs.length===0" class="text-center py-2 caption">
            Не найдено
          </div>
          <v-list v-else dense three-line class="pa-0">
            <template v-for="(notif, i) in notifs">
              <v-list-item :key="'notif'+i">
                <v-list-item-content>
                  <v-list-item-title>{{notif.name}}</v-list-item-title>
                  <v-list-item-subtitle class="font-weight">{{notif.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="my-2">
                  <v-btn
                      @click="notifClose(i)"
                      icon small color="grey"
                  >
                    <v-icon color="grey" small>mdi-close</v-icon>
                  </v-btn>
                  <v-btn v-if="!$tools.isEmpty(notif.url)"
                         @click="openUrl(i, notif)"
                         icon small color="grey"
                  >
                    <v-icon color="grey" small>mdi-open-in-new</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="notifs.length-1>i" :key="'notifDivider'+i"/>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-sheet>
  </v-menu>

</template>

<script>


import State from "@/plugins/state";

export default {
  data: () => ({
    state: new State(),
    notifs:[],
  }),
  mounted(){
    /*
    this.loadNotifsLocalStorage();
    Vue.prototype.$notifsMessageListener.push((v)=>{
      this.notifs.push(v);
      this.saveNotifsLocalStorage();
    })

     */
  },
  methods: {
    loadNotifsLocalStorage(){
      try {
        this.notifs = JSON.parse(localStorage.notifs);
      } catch(e) {
        this.notifs = [];
      }
      window.addEventListener('storage', () => {
        try {
          this.notifs = JSON.parse(localStorage.notifs);
          // eslint-disable-next-line no-empty
        } catch(e) {
        }
      });
    },
    saveNotifsLocalStorage(){
      localStorage.notifs = JSON.stringify(this.notifs);
    },
    notifClose(i){
      this.notifs.splice(i, 1);
      this.saveNotifsLocalStorage();
    },
    openUrl(i, notif){
      this.notifs.splice(i, 1);
      this.saveNotifsLocalStorage();
      if(!this.$tools.isEmpty(notif.url))
        this.$tools.openUrl(notif.url);
      if(this.notifs.length===0)
        this.state.modalClose();
    }
  }
}
</script>