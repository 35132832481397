<template>
  <div>
    <call-state-item v-if="callEntry.callGroup.status==='fail'" :phoneNumber="phoneNumber" :blink="blink" color="red darken-1" icon="mdi-phone-missed" />
    <call-state-item v-else-if="callEntry.callGroup.status==='failNonWork'" :phoneNumber="phoneNumber" :blink="blink" color="red darken-1" icon="mdi-phone-missed" />
    <call-state-item v-else-if="callEntry.callGroup.status==='success'" :phoneNumber="phoneNumber" :blink="blink" color="green darken-1" icon="mdi-phone-hangup" />
    <div v-else-if="callEntry.state==='Appeared'">
      <call-state-item v-if="callEntry.callGroup.callDirection===0" :phoneNumber="phoneNumber" :blink="blink" color="deep-orange" icon="mdi-phone"/>
      <call-state-item v-else-if="callEntry.callGroup.callDirection===1" :phoneNumber="phoneNumber" :blink="blink" color="deep-orange" icon="mdi-phone-incoming" />
      <call-state-item v-else-if="callEntry.callGroup.callDirection===2" :phoneNumber="phoneNumber" :blink="blink" color="deep-orange" icon="mdi-phone-outgoing" />
    </div>
    <call-state-item v-else-if="callEntry.state==='OnHold'" :phoneNumber="phoneNumber" :blink="blink" color="blue" icon="mdi-phone-lock" />
    <call-state-item v-else-if="callEntry.state==='Connected'" :phoneNumber="phoneNumber" :blink="blink" color="blue" icon="mdi-phone-in-talk" />
    <call-state-item v-else-if="callEntry.state==='Disconnected'" :phoneNumber="phoneNumber" :blink="blink" color="red darken-1" icon="mdi-phone-missed" />
  </div>
</template>

<script>
import CallStateItem from "@/views/Call/CallStateItem.vue";

export default {
  components: {CallStateItem},
  props:['callEntry','phoneNumber','blink'],
}
</script>

