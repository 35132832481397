import { Model } from '@vuex-orm/core'
import ComBuilding from "@/model/ComBuilding";
import ComRoom from "@/model/ComRoom";

export default class ComFloor extends Model {
    static entity = 'comFloors'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),

            comBuilding_id: this.attr(null),
            comBuilding: this.belongsTo(ComBuilding, 'comBuilding_id'),

            comRooms: this.hasMany(ComRoom, 'comFloor_id'),
        }
    }
}