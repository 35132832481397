import { Model } from '@vuex-orm/core'
import User from "@/model/User";
import Spec from "@/model/Spec";
import UserSpecPlan from "@/model/UserSpecPlan";
import ComDiv from "@/model/ComDiv";

export default class UserSpec extends Model {
    static entity = 'userSpecs'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),

            user_id: this.attr(null),
            user: this.belongsTo(User, 'user_id'),

            spec_id: this.attr(null),
            spec: this.belongsTo(Spec, 'spec_id'),

            commentAdmin: this.attr(null),

            userSpecPlans: this.hasMany(UserSpecPlan, 'userSpec_id'),

            comDiv_id: this.attr(null),
            comDiv: this.belongsTo(ComDiv, 'comDiv_id'),
        }
    }
}