<template>
  <v-menu
      v-if="!!this.$slots.content ? true : !$tools.isEmpty(content)"
      :offset-x="typeof bottom=='undefined'"
      :offset-y="typeof bottom!='undefined'"
      open-on-hover
      :open-delay="500"
      :disabled="typeof disabled===undefined ? false : disabled"
      :z-index="1000"
  >
    <template v-slot:activator="{ attrs, on }">
      <span v-bind="attrs" v-on="on">
        <slot name="action"/>
      </span>
    </template>
    <div v-if="!$tools.isEmpty(content)" class="pa-2 body-2" v-html="$tools.nl2br(content)"></div>
    <div v-else class="px-2 pt-2">
      <v-sheet max-width="400">
        <slot name="content"/>
      </v-sheet>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: [
    'content',
    'disabled',
    'bottom',
  ]
}
</script>
