<template>
  <div v-if="typeof small!=='undefined'">
    <v-icon small :color="color">{{icon}}</v-icon>
  </div>
  <v-sheet
      v-else-if="typeof phoneNumber==='undefined'"
      width="34" height="34"
      style="background-color:rgba(255,255,255,0.5)" outlined rounded
      class="align-content-center"
  >
    <v-sheet :color="color" width="32" height="32" outlined rounded class="align-content-center text-center">
      <v-icon size="20" color="white">{{icon}}</v-icon>
    </v-sheet>
  </v-sheet>
  <div v-else>
    <v-sheet :class="blink===true ? 'blink' : ''" :color="color" outlined rounded>
      <v-row no-gutters align="center">
        <v-col cols="auto">
          <v-sheet color="transparent" class="align-content-center text-center" width="40" height="40">
            <v-icon size="20" color="white">{{icon}}</v-icon>
          </v-sheet>
        </v-col>
        <v-col class="text-h6 white--text">
          {{$tools.phoneFormat(phoneNumber)}}
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props:['blink','color','icon','phoneNumber','small']
}
</script>

