<template>
  <v-sheet
      :class="
        (typeof fullWidth==='undefined' ? 'd-inline-block' : '')+' '+
        (typeof small==='undefined' ? 'body-2 px-2 py-1' : 'caption px-1')+' '+
        (typeof clickable==='undefined' ? '' : 'clickable')+' '+
        'align-center'
      "
      :width="typeof fullWidth==='undefined' ? '' : '100%'"
      :dark="typeof dark!=='undefined'"
      rounded
      :color="typeof color==='undefined' ? 'grey lighten-4' : color"
      @click="$emit('click')"
  >
    <slot/>
  </v-sheet>
</template>

<script>
export default {
  props: ['color','small','dark','fullWidth','clickable'],
}
</script>

<style scoped>
.clickable:hover{
  color:#1976d2 !important;
}
</style>