<template>
  <div v-if="typeof left!=='undefined'" class="py-8">
    <v-row no-gutters>
      <v-col v-if="typeof icon!=='undefined'" cols="auto"><v-icon class="mb-2 mr-2" size="36" color="grey">{{icon}}</v-icon></v-col>
      <v-col>
        <div class="muted1" v-html="$tools.nl2br(text)"></div>
      </v-col>
    </v-row>
  </div>
  <div v-else class="text-center py-8">
    <v-icon v-if="typeof icon!=='undefined'" class="mb-2" size="36" color="grey">{{icon}}</v-icon>
    <div class="muted1" v-html="$tools.nl2br(text)"></div>
  </div>
</template>

<script>
export default {
  props: ['icon','text','left']
}
</script>
