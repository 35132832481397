import { Model } from '@vuex-orm/core'
import UserProfile from "@/model/UserProfile";
import UserSpec from "@/model/UserSpec";

export default class User extends Model {
    static entity = 'users'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            firstName: this.attr(null),
            lastName: this.attr(null),
            secondName: this.attr(null),
            color: this.attr(null),
            phone: this.attr(null),
            imageUrl: this.attr(null),
            type: this.attr(null),
            userProfile: this.hasOne(UserProfile, 'user_id'),
            userSpecs: this.hasMany(UserSpec, 'user_id'),
            insurOms: this.attr(null),
            insurOmsEnd: this.attr(null),
            insurOmsDes: this.attr(null),
            insurDms: this.attr(null),
            insurDmsEnd: this.attr(null),
            insurDmsDes: this.attr(null),
            //visits: this.hasMany(Visit, 'user_id')
        }
    }
}