<template>
  <span v-if="callGroup.callDirection===0">
    <v-icon :size="typeof widget==='undefined' ? 14 : 16" :class="typeof widget==='undefined' ? 'mr-1' : 'mr-2'" :color="typeof widget==='undefined' ? '' : 'grey'">mdi-phone-incoming-outgoing</v-icon>
    <span :class="typeof widget==='undefined' ? '' : 'grey--text'">Внутренний</span>
  </span>
  <span v-else-if="callGroup.callDirection===1">
    <v-icon :size="typeof widget==='undefined' ? 14 : 16" :class="typeof widget==='undefined' ? 'mr-1' : 'mr-2'">mdi-phone-incoming</v-icon>
    <span>Входящий</span>
  </span>
  <span v-else-if="callGroup.callDirection===2">
    <v-icon :size="typeof widget==='undefined' ? 14 : 16" :class="typeof widget==='undefined' ? 'mr-1' : 'mr-2'">mdi-phone-outgoing</v-icon>
    <span>Исходящий</span>
  </span>
</template>

<script>

export default {
  props:['callGroup','widget'],
}
</script>

<style scoped>
.text span{
  text-transform: initial !important
}
</style>

