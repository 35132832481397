<template>
  <div>
    <div class="caption mb-1">
      {{ title }}
      <v-icon v-if="typeof editable!=='undefined'" class="mt-n1" small>mdi-pencil</v-icon>
    </div>
    <div class="mb-2 position-relative body-2">
      <slot/>
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'editable'
  ]
}
</script>
