
export default class State{
    constructor() {
        this.stateClear();
        this.isInit = true;
    }
    isInit = false
    isRequest = false
    isRequestCancel = false
    isLoading = false
    isLoadingSecond = false
    isError = false
    errorMessage = null
    isSuccess = false
    step = 0
    dataFun = null

    stateClear(){
        this.isInit = false;
        this.isRequest = false;
        this.isRequestCancel = false;
        this.isLoading = false;
        this.isLoadingSecond = false;
        this.isError = false;
        this.isSuccess = false;
    }
    stateInit(){this.stateClear(); this.isInit = true}
    stateRequest(){this.stateClear(); this.isRequest = true}
    stateRequestCancel(){this.stateClear(); this.isRequestCancel = true}
    stateLoading(dataFun=null){
        this.stateClear();
        this.isLoading = true
        if(dataFun!==null)
            this.dataFun = dataFun;
        if(this.dataFun!==null)
            this.dataFun();
    }
    stateLoadingSecond(){this.stateClear(); this.isLoadingSecond = true}
    stateError(errorMessage){this.stateClear(); this.isError = true; this.errorMessage = errorMessage}
    stateSuccess(){this.stateClear(); this.isSuccess = true}
    stepPrev(){
        if(this.step>0){
            this.step--;
            return true;
        } else {
            return false;
        }
    }
    stepNext(){this.step++}

    setRequestBuilderState(v){
        this.isLoading = v.loading;
        this.isSuccess = v.success;
        this.isError = v.error;
        this.errorMessage = v.errorMessage;
    }

    isModalOpen = false
    valid = null
    modalData = null
    modalData1 = null
    modalData2 = null
    modalData3 = null
    modalOpen(modalData=null, modalData1=null, modalData2=null, modalData3=null){
        this.isModalOpen = true;
        this.modalData = modalData;
        this.modalData1 = modalData1;
        this.modalData2 = modalData2;
        this.modalData3 = modalData3;
    }
    modalClose(modalData=null, modalData1=null, modalData2=null, modalData3=null){
        this.isModalOpen = false;
        setTimeout(()=>{
            this.modalData = modalData;
            this.modalData1 = modalData1;
            this.modalData2 = modalData2;
            this.modalData3 = modalData3;
        }, 250);
    }

}