import { Model } from '@vuex-orm/core'
import ComBranch from "@/model/ComBranch";
import ServiceCat from "@/model/ServiceCat";

export default class Com extends Model {
    static entity = 'coms'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            name: this.attr(null),

            comBranchs: this.hasMany(ComBranch, 'comBranch_id'),
            serviceCats: this.hasMany(ServiceCat, 'com_id'),
        }
    }
}