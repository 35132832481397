import { Model } from '@vuex-orm/core'

export default class Log extends Model {
    static entity = 'logs'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            entityName: this.attr(null),
            entityId: this.attr(null),
            text: this.attr(null),
            user: this.attr(null),
            created: this.attr(null),
        }
    }
}