<template>
  <v-btn @click="active ? disable() : enable()" :outlined="active" icon class="mr-2">
    <v-icon>mdi-microphone</v-icon>
    <v-avatar class="position-absolute" :size="level>48 ? 48 : level" style="background:rgba(255,255,255,0.3);transition: all 0.05s ease-out"/>
  </v-btn>
</template>

<script>
export default {
  props:["rules"],
  data: () => ({
    rec: null,
    speechRecognition: null,
    active: false,
    audioContext:null,
    level:0,
    levelInterval:null,
  }),
  mounted() {
    this.speechRecognition =
        window.SpeechRecognition ||
        window.webkitSpeechRecognition;
  },
  beforeDestroy() {
    this.disable();
  },
  methods:{
    speechStart(){
      //const grammar = "#JSGF V1.0; grammar colors; public <color> = aqua | azure | beige | bisque | black | blue | brown | chocolate | coral | crimson | cyan | fuchsia | ghostwhite | gold | goldenrod | gray | green | indigo | ivory | khaki | lavender | lime | linen | magenta | maroon | moccasin | navy | olive | orange | orchid | peru | pink | plum | purple | red | salmon | sienna | silver | snow | tan | teal | thistle | tomato | turquoise | violet | white | yellow ;";
      // eslint-disable-next-line no-undef

      this.rec = new this.speechRecognition();
      //const speechRecognitionList = new SpeechGrammarList();
      //speechRecognitionList.addFromString(grammar, 1);
      //recognition.grammars = speechRecognitionList;
      this.rec.continuous = true;
      this.rec.lang = "ru-RU";
      this.rec.interimResults = false;
      this.rec.maxAlternatives = 1;
      this.rec.start();
      this.rec.onresult = (event) => {
        var eventLast = event.results[event.results.length-1];
        var conf = eventLast[0].confidence;
        let text = eventLast[0].transcript;
        console.log(text, conf);

        text = text.replaceAll('точка', '.');
        text = text.replaceAll('запятая', ',');

        let el = document.querySelector(":focus");
        if(el!==null) {
          if(this.$tools.isEmpty(this.rules)){
            el.value += text;
          } else if(this.rules.includes("divideByDot")){
            text = text.trim();
            text = text.charAt(0).toUpperCase()+text.slice(1)+'.';
            el.value += text;
          }
          el.dispatchEvent(new Event('input'));
        }

        document.querySelectorAll("*").forEach(v1=>{
          if(
              v1.textContent.toLowerCase().includes(text.trim().toLowerCase()) &&
              v1.childNodes.length===1 &&
              v1.childNodes[0].nodeType===Node.TEXT_NODE
          ){
            v1.parentNode.classList.add('blink1');
            setTimeout(()=>{
              v1.parentNode.classList.remove('blink1');
            },2500);
          }
        });
      };
      /*
      this.rec.onend = () => {
        this.speechStart();
      };
       */
    },
    levelStart(){
      navigator.mediaDevices.getUserMedia({
        audio: true
      }).then(stream => {
        this.audioContext = new AudioContext();
        const source = this.audioContext.createMediaStreamSource(stream);
        const analyzer = this.audioContext.createAnalyser();
        source.connect(analyzer);
        const array = new Uint8Array(analyzer.fftSize);
        this.levelInterval = setInterval(()=>{
          analyzer.getByteTimeDomainData(array);
          this.level = array.reduce((max, current) => Math.max(max, Math.abs(current - 127)), 0) / 128;
          this.level *= 48*2;
        },50)
      });
    },
    enable(){
      this.active = true;
      this.speechStart();
      this.levelStart();
    },
    disable(){
      this.active = false;
      if(this.rec!==null)
        this.rec.stop();
      if(this.levelInterval!==null){
        this.audioContext.close();
        clearInterval(this.levelInterval);
      }
    },
  }
}
</script>

<style>
.blink1 {
  animation: blink1 0.3s infinite;
  border-radius: 0.5rem;
}

@keyframes blink1 {
  0% {
    background-color: #000;
  }
  100% {
    background-color: #FFF;
  }
}
</style>