import { Model } from '@vuex-orm/core'
import ComBranch from "@/model/ComBranch";
import ComFloor from "@/model/ComFloor";

export default class ComBuilding extends Model {
    static entity = 'comBuildings'
    static primaryKey = "id";
    static fields () {
        return {
            id: this.attr(null),
            street: this.attr(null),

            comBranch_id: this.attr(null),
            comBranch: this.belongsTo(ComBranch, 'comBranch_id'),

            comFloors: this.hasMany(ComFloor, 'comBuilding_id'),
        }
    }
}